<template>
  <el-form
    ref="form"
    label-position="top"
    :model="formData"
    :rules="formRules"
  >
    <el-form-item
      label="内容"
      prop="content"
    >
      <el-input
        v-model="formData.content"
        type="textarea"
        :autosize="{minRows: 4}"
        placeholder="请输入内容"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="图片"
      prop="images"
    >
      <c-upload-image v-model="formData.images"></c-upload-image>
    </el-form-item>
    <div class="g-center">
      <b-btn-confirm
        :loading="submitLoading"
        @click="onSubmit"
      >
      </b-btn-confirm>
    </div>
  </el-form>
</template>

<script>
import CUploadImage from '@/components/upload/image'
import { createFeedback } from '@/api/feedback'

export default {
  components: {
    CUploadImage,
  },
  props: {
    data: {},
  },
  data() {
    return {
      submitLoading: false,
      formData: {
        content: '',
        images: [],
      },
      formRules: {
        content: [{ required: true, message: '必填项', trigger: 'blur' }],
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        this.formData = this.$tools.copy(val)
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          createFeedback(this.formData)
            .then(res => {
              this.$message.success('创建成功')
              this.$emit('success')
            })
            .finally(() => {
              this.submitLoading = false
            })
        }
      })
    },
  },
}
</script>
