import request from '../axios'

/**
 * 分页获取意见列表
 */
export function getFeedbackPage(params) {
  return request({
    url: '/api/notes',
    params,
  })
}

/**
 * 获取意见详情
 */
export function getFeedbackDetail(id) {
  return request({
    url: `/api/notes/${id}`,
  })
}

/**
 * 新建意见
 */
export function createFeedback(params) {
  return request({
    method: 'post',
    url: '/api/notes',
    data: params,
  })
}

/**
 * 查询回复列表
 */
export function getFeedbackReplyPage(params) {
  const id = params.id
  return request({
    url: `/api/notes/${id}/replys`,
    params,
  })
}

/**
 * 添加回复
 */
export function createFeedbackReply(id, params) {
  return request({
    method: 'post',
    url: `/api/notes/${id}/replys`,
    data: params,
  })
}
