<template>
  <el-scrollbar
    y
    view-style="padding: 0px 15px 15px;"
  >
    <div class="g-p-20 g-bg g-page-list">
      <el-form
        ref="form"
        :model="searchData"
        inline
        label-position="top"
      >
        <el-form-item
          label="创建时间"
          prop="time"
        >
          <el-date-picker
            v-model="searchData.time"
            type="daterange"
          ></el-date-picker>
        </el-form-item>
        <el-form-item style="vertical-align: bottom;">
          <c-btn-search
            @search="onRefresh"
            @reset="onReset"
            @create="onAdd"
          ></c-btn-search>
        </el-form-item>
      </el-form>
      <el-table :data="list">
        <el-table-column
          type="index"
          label="序号"
        ></el-table-column>
        <el-table-column
          label="内容"
          prop="content"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="created_at"
        ></el-table-column>
        <el-table-column
          label="创建人"
          prop="user.name"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="100"
        >
          <template slot-scope="{ row }">
            <c-btn-table
              :show="['info']"
              @info="onView(row)"
            ></c-btn-table>
          </template>
        </el-table-column>
      </el-table>
      <b-pagination
        :page="page"
        @change="fetchList"
      ></b-pagination>
    </div>

    <b-dialog
      v-model="detailVisible"
      width="600px"
      title="新建"
    >
      <detail-view
        :data="formData"
        @success="onCreateSuccess"
      ></detail-view>
    </b-dialog>
  </el-scrollbar>
</template>

<script>
import DetailView from './detail.vue'
import { getFeedbackPage } from '@/api/feedback'
export default {
  components: {
    DetailView,
  },
  data() {
    return {
      list: [],
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      searchData: {},
      detailVisible: false,
      formData: {},
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      const params = {
        page: this.page.current,
      }
      getFeedbackPage(params).then(res => {
        this.list = res.data
        this.page.total = res.total
      })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onReset() {
      this.$refs.form.resetFields()
      this.fetchList()
    },
    onAdd() {
      this.formData = {
        content: '',
        images: [],
      }
      this.detailVisible = true
    },
    onCreateSuccess() {
      this.onRefresh()
      this.detailVisible = false
    },
    onView(row) {
      this.$router.push({ path: '/feedback/info', query: { id: row.id } })
    },
  },
}
</script>
